<template>
  <section class="hero-page">
    <div class="hero-page__wrap">
        <div class="hero-page__top">
          <h1 class="hero-page__position">Front End Developer</h1>
        </div>
        <div class="hero-page__bottom">
          <div class="hero-page__description">
            <div class="hero-page__name">
              <p>Olh<span class="hero-page__mark hero-page__mark--color">a</span></p>
              <p class="hero-page__family">Kl<span class="hero-page__mark hero-page__mark--remove-color">y</span>mas</p>
            </div>
            <div class="hero-page__head">
              <p class="hero-page__mark hero-page__mark--color">P</p>
              <p class="hero-page__mark hero-page__mark--color">o</p>
              <p class="hero-page__mark hero-page__mark--color">r</p>
              <p class="hero-page__mark hero-page__mark--color">t</p>
              <p class="hero-page__mark hero-page__mark--remove-color">f</p>
              <p class="hero-page__mark hero-page__mark--remove-color">o</p>
              <p class="hero-page__mark hero-page__mark--remove-color">l</p>
              <p class="hero-page__mark hero-page__mark--remove-color">i</p>
              <p class="hero-page__mark hero-page__mark--remove-color">o</p>
            </div>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroPage"
}
</script>

<style lang="scss">
@import '@/assets/scss/pages/heroPage.scss';
</style>
