<template>
  <div class="loader">
    <div class="loader__wrapper">
      <div class="loader__layout"></div>
      <div class="loader__ind"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>